import Vue from 'vue'

import compassApi from '@/services/compass-api'

const state = {
  error: null,
  loading: false,
  loaded: false,
  reports: [], // List of reports
  loadingBuildings: false,
  loadedBuildings: false,
  reportBuildings: [], // List of reporting buildings
}

// Getters
const getters = {
  getReports(state) {
    return () => {
      return state.reports
    }
  },

  getBuildings(state) {
    return () => {
      return state.reportBuildings
    }
  },
}

// Actions
const actions = {
  // Load report of one portfolio and year
  async getReportByPortfolioIdAndYear({ state, commit }, { portfolioId, year }) {
    commit('reportLoading')
    try {
      const report = await compassApi.call(`/portfolios/${portfolioId}/reporting/reida/reports/${year}`)
      commit('reportLoaded', {
        portfolioId: portfolioId,
        year: year,
        report: report,
      })
    } catch (e) {
      commit('reportLoaded', {
        portfolioId: portfolioId,
        year: year,
        report: null,
      })
    }
  },

  // Load buildings of one portfolio and year
  async getReportBuildingsByPortfolioIdAndYear({ state, commit }, { portfolioId, year }) {
    commit('reportBuildingsLoading', { year })
    try {
      const buildings = await compassApi.call(`/portfolios/${portfolioId}/reporting/reida/reports/${year}/buildings`)
      commit('reportBuildingsLoaded', {
        year: year,
        buildings: buildings,
      })
    } catch (NotFoundError) {
      commit('reportBuildingsLoaded', {
        year: year,
        buildings: null,
      })
    }
  },

  /*
   * Create report for a portfolio and year
   */
  async createReport({ state, commit, dispatch }, { portfolioId, year }) {
    try {
      commit('reportLoading')
      const report = await compassApi.call(`/portfolios/${portfolioId}/reporting/reida/reports/${year}/new`)
      commit('reportLoaded', {
        year: year,
        report: report,
      })
      dispatch('getReportBuildingsByPortfolioIdAndYear', { portfolioId, year })
    } catch (error) {
      commit('reportError', error)
    }
  },

  async reset({ commit }) {
    commit('reset')
  },

  async resetError({ commit }) {
    commit('resetError')
  },
}

// Mutations
const mutations = {
  // Reset store
  reset(state) {
    Vue.set(state, 'error', null)
    Vue.set(state, 'reports', [])
    Vue.set(state, 'reportBuildings', [])
  },

  resetError(state) {
    Vue.set(state, 'error', null)
  },

  // When reports are being loaded
  reportLoading(state) {
    Vue.set(state, 'loaded', false)
    Vue.set(state, 'loading', true)
  },

  // When reports have been loaded
  reportLoaded(state, { year, report }) {
    // Replace the report if year already exists
    const otherReports = state.reports.filter((r) => r.reportYear !== year)
    if (report) {
      Vue.set(state, 'reports', [...otherReports, report])
    } else {
      Vue.set(state, 'reports', [...otherReports])
    }
    Vue.set(state, 'loaded', true)
    Vue.set(state, 'loading', false)
  },

  // When buildings are being loaded
  reportBuildingsLoading(state) {
    Vue.set(state, 'loadedBuildings', false)
    Vue.set(state, 'loadingBuildings', true)
  },

  // When buildings have been loaded
  reportBuildingsLoaded(state, { year, buildings }) {
    // Replace the buildings if year already exists
    const otherBuildings = state.reportBuildings.filter((b) => b.reportYear !== year)
    if (buildings) {
      Vue.set(state, 'reportBuildings', [...otherBuildings, ...buildings])
    } else {
      Vue.set(state, 'reportBuildings', [...otherBuildings])
    }
    Vue.set(state, 'loadedBuildings', true)
    Vue.set(state, 'loadingBuildings', false)
  },

  // When an error occurred
  reportError(state, error) {
    Vue.set(state, 'error', error)
    Vue.set(state, 'loading', false)
    Vue.set(state, 'loaded', false)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
