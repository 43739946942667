import compassApi from '@/services/compass-api'
import _ from 'lodash'

// initial state
const state = {
  buildingSimulationQueue: {},
}

// getters
const getters = {
  buildingSimulationQueue: (state) => (portfolioId) => {
    if (state.buildingSimulationQueue && state.buildingSimulationQueue[portfolioId]) {
      return state.buildingSimulationQueue[portfolioId]
    }
    return []
  },
}

// mutations
const mutations = {
  setBuildingSimulationQueue(state, { queue }) {
    if (!_.isEqual(state.buildingSimulationQueue, queue)) {
      state.buildingSimulationQueue = queue
    }
  },
}

// actions
const actions = {
  async loadBuildingSimulationQueue({ commit }) {
    const data = await compassApi.call(`/portfolios/simulation-queue`)
    if (data) {
      commit('setBuildingSimulationQueue', { queue: data })
    }
  },
  async resetBuildingSimulationQueue({ commit }) {
    commit('setBuildingSimulationQueue', { queue: {} })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
