import Vue from 'vue'

import compassApi from '@/services/compass-api'

const state = {
  loading: true,
  loaded: false,
  sustainabilityYears: [], // List of sustainability years
}

// Getters
const getters = {
  getSustainabilityYears(state) {
    return () => {
      return state.sustainabilityYears
    }
  },
}

// Actions
const actions = {
  // Load energy carrier sustainability indicator years of one portfolio
  async loadSustainabilityYearsByPortfolioId({ state, commit }, portfolioId) {
    commit('sustainabilityYearsLoading')
    const sustainabilityYears = await compassApi.call(`/portfolios/${portfolioId}/sustainability/years`)
    commit('sustainabilityYearsLoaded', {
      sustainabilityYears: sustainabilityYears.sort((a, b) => a.year - b.year),
    })
  },

  /*
   * Create, edit and delete Sustainability Years
   */
  async addSustainabilityYear({ dispatch }, { sustainabilityYear, portfolioId }) {
    await compassApi.call(`/portfolios/${portfolioId}/sustainability/years`, 'POST', sustainabilityYear)
    dispatch('portfolio/refreshDetailsById', portfolioId, { root: true })
  },

  async updateSustainabilityYear({ dispatch }, { id, sustainabilityYear, portfolioId }) {
    await compassApi.call(`/portfolios/${portfolioId}/sustainability/years/${id}`, 'PUT', sustainabilityYear)
  },

  async deleteSustainabilityYear({ dispatch }, { id, portfolioId }) {
    await compassApi.call(`/portfolios/${portfolioId}/sustainability/years/${id}`, 'DELETE')
  },
}

// Mutations
const mutations = {
  // When sustainability years are being loaded
  sustainabilityYearsLoading(state) {
    Vue.set(state, 'loaded', false)
    Vue.set(state, 'loading', true)
    Vue.set(state, 'sustainabilityYears', [])
  },

  // When sustainability years have been loaded
  sustainabilityYearsLoaded(state, { sustainabilityYears }) {
    Vue.set(state, 'sustainabilityYears', [...sustainabilityYears])
    Vue.set(state, 'loaded', true)
    Vue.set(state, 'loading', false)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
