import compassApi from '@/services/compass-api'

let user = JSON.parse(localStorage.getItem('user'))

// initial state
const state = {
  status: user ? { loggedIn: true } : {},
  user,
}

// getters
const getters = {
  name(state) {
    if (state.user) {
      return `${state.user.first_name} ${state.user.last_name}`
    }
    return null
  },
  email(state) {
    return state.user && state.user.email
  },
  company(state) {
    return state.user && state.user.company_name
  },
  token(state) {
    return state.user && state.user.authentication_token
  },
  admin(state) {
    return Boolean(state.user && state.user.admin)
  },
  loggedIn(state) {
    return Boolean(state.status.loggedIn)
  },
  loggingIn(state) {
    return Boolean(state.status.loggingIn)
  },
  loginFailed(state) {
    return Boolean(state.status.loginFailed)
  },
}

// actions
const actions = {
  async login({ commit }, { email, password }) {
    commit('loginRequest', { email })
    try {
      const user = await compassApi.login(email, password)
      commit('loginSuccess', user)
    } catch (error) {
      commit('loginFailure', error)
    }
  },

  logout({ commit }) {
    compassApi.logout()
    commit('logout')
  },

  reset({ commit }) {
    commit('logout')
  },
}

// mutations
const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true }
    state.user = user
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true }
    state.user = user
  },
  loginFailure(state) {
    state.status = { loginFailed: true }
    state.user = null
  },
  logout(state) {
    state.status = {}
    state.user = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
