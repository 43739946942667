const buildingUsageColors = {
  RESIDENTIAL: '#1EBBD7',
  RESIDENTIAL_SFH: '#14D7B5',
  OFFICE: '#996699',
  SCHOOLS: '#E27A9F',
  RETAIL: '#A3B3C2',
  RESTAURANT: '#9FD9D3',
  EVENT_BUILDING: '#EBC4A9',
  HOSPITAL: '#D84570',
  INDUSTRIAL: '#8E4B70',
  STORAGE: '#F7D0B5',
  SPORTS: '#E27A9F',
  INDOOR_SWIMMING: '#F093B8',
  UNKNOWN: '#808080',
}

const dataQualityColors = {
  FULL: '#3E8C2A',
  ESTIMATE: '#B3D100',
  GIS_ESTIMATE: '#CCCC00',
  MISSING: '#E66666',
}

const dataQualityReidaReportColors = {
  CONFORM: '#3E8C2A',
  NOT_CONFORM: '#E66666',
}

const ewsFeasibilityColors = {
  NON_ADMISSIBLE: '#8E0E1B',
  ADMISSIBLE: '#3E8C2A',
  LIMITATIONS: '#B3D100',
  ERROR: '#B3ADAD',
  UNKNOWN: '#D4D4D4',
}

const gwFeasibilityColors = {
  NON_ADMISSIBLE: '#8E0E1B',
  ADMISSIBLE: '#3E8C2A',
  LIMITATIONS: '#B3D100',
  BAD_GEOLOGY: '#E11E26',
  ERROR: '#B3ADAD',
  UNKNOWN: '#D4D4D4',
}

const districtAvailabilityColors = {
  NONE: '#E11E26',
  UNKNOWN: '#D4D4D4',
  EXISTING: '#3E8C2A',
  EXISTING_NEAR: '#CCCC66',
  PLANNED: '#4CB01E',
  PLANNED_NEAR: '#CCCC99',
  EVALUATION: '#CCCC00',
  EVALUATION_NEAR: '#CCCCCC',
  ERROR: '#B3ADAD',
  HEAT_POTENTIAL_NEAR: '#B3CC66',
}

const usageColors = {
  ROOM_HEAT: '#EB3D06',
  HOT_WATER: '#F37D84',
  GENERAL: '#F6D218',
  TENANTS: '#F2E801',
}

const meterTypeColors = {
  END_ENERGY_HEATER: '#EB3D06',
  ELECTRICITY_GENERAL: '#F6D218',
  ELECTRICITY_TENANTS: '#F2E801',
  ELECTRICITY_PV: '#5BDD87',
}

const defaultEnergyCarrierColors = {
  HEATING_OIL: '#732A2A',
  NATURAL_GAS: '#C94B19',
  DISTRICT_HEAT: '#788F8F',
  LOCAL_HEAT: '#617373',
  SOLAR_HEAT: '#CAE405',
  PELLET: '#8F5B33',
  WOOD_CHIPS: '#5C3C2E',
  ELECTRICITY_GRID: '#F6D218',
  ELECTRICITY_OWNUSE: '#B7D830',
  ELECTRICITY_FEEDIN: '#4EDBA0',
}

const heatingColors = {
  OIL: '#732A2A',
  GAS: '#C94B19',
  DISTRICT: '#788F8F',
  LOCAL: '#617373',
  PELLET: '#8F5B33',
  WOOD_CHIPS: '#5C3C2E',
  HP_EWS: '#8CAED6',
  HP_AIR: '#D6E5F7',
  HP_GW: '#B3CCE6',
}

const measureColors = {
  WINDOWS: '#70C7EC',
  WALLS: '#5C95E8',
  ROOF: '#4B62E5',
  BASEMENT: '#3D38C0',
  HEATING: '#E82A63',
  PV: '#5BDD87',
  ELECTRICITY: '#F3B231',
  ZONES: '#D530D8',
}

const scenarioColors = [
  //
  '#1EA0E1',
  '#1E86E1',
  '#1E6CE1',
  '#1E52E1',
  '#1E38E1',
  '#1E1EE1',
]

const energyCarrierColors = [
  //
  '#FF5511',
  '#FF7711',
  '#FF9911',
  '#FFbb11',
  '#FFdd11',
  '#FFFF11',
]

const pvNpvColors = {
  investment: '#C4A76A',
  subsidies: '#C4C46A',
  revenue_ownuse: '#6AA3C4',
  revenue_feedin: '#8AB8D6',
  operation_costs: '#C4876A',
  cumulative_total: '#666666',
}

const efficiencyClassColors = {
  '+': '#00AC60',
  A: '#00AC60',
  B: '#4FB859',
  C: '#C3DE43',
  D: '#E8E800',
  E: '#F8CE20',
  F: '#F09D0F',
  G: '#E22E31',
}

const scopeColors = {
  s1e: '#3960EA',
  s2e: '#201CC7',
  s3e: '#3A09A0',
}

const pvColors = {
  el_ownuse: '#B7D830',
  el_feedin: '#4EDBA0',
}

const colors = {
  ewsFeasibilityColors,
  gwFeasibilityColors,
  districtAvailabilityColors,
  buildingUsageColors,
  usageColors,
  meterTypeColors,
  defaultEnergyCarrierColors,
  heatingColors,
  measureColors,
  scenarioColors,
  energyCarrierColors,
  pvNpvColors,
  efficiencyClassColors,
  dataQualityColors,
  dataQualityReidaReportColors,
  scopeColors,
  pvColors,
}

export default colors
