import _ from 'lodash'

const state = {
  // Current search query
  search: '',
  sort_order: 'identifier',
  sort_reverse: false,
  filters: {},
  reida: {
    year: null,
    types: ['EXISTING_AND_CONFORM'], // ['NOT_CONSIDERED', 'NOT_EXISTING', 'EXISTING_NOT_CONFORM',
    //  'EXISTING_AND_CONFORM' ]
  },
  status: [],
}

// Getters
const getters = {
  get(state) {
    return { ...state }
  },
}

// Actions
const actions = {
  setSearchText({ dispatch, commit }, { portfolioId, search }) {
    commit('setSearchText', search)
    dispatch('portfolioBuildingTags/refresh', { portfolioId: portfolioId }, { root: true })
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
    dispatch('chartData/refresh', {}, { root: true })
    dispatch('reportChartData/refresh', null, { root: true })
  },

  setSortOrder({ dispatch, commit }, { order }) {
    commit('setSortOrder', order)
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
    dispatch('chartData/refresh', {}, { root: true })
    dispatch('reportChartData/refresh', null, { root: true })
  },

  setSortReverse({ dispatch, commit }, { value }) {
    commit('setSortReverse', value)
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
    dispatch('chartData/refresh', {}, { root: true })
    dispatch('reportChartData/refresh', null, { root: true })
  },

  setTagFilters({ dispatch, commit }, { payload, portfolioId }) {
    commit('setTagFilters', payload)
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
    dispatch('chartData/refresh', {}, { root: true })
    dispatch('reportChartData/refresh', null, { root: true })
    dispatch('portfolioBuildingTags/refresh', { portfolioId: portfolioId }, { root: true })
  },

  setReidaYear({ dispatch, commit }, { year }) {
    commit('setReidaYear', { year })
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
    dispatch('chartData/refresh', {}, { root: true })
    dispatch('reportChartData/refresh', null, { root: true })
  },

  toggleReidaType({ dispatch, commit }, { type }) {
    commit('toggleReidaType', { type })
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
    dispatch('chartData/refresh', {}, { root: true })
    dispatch('reportChartData/refresh', null, { root: true })
  },

  setStatusFilter({ dispatch, commit }, { selectedTypes }) {
    commit('setStatusFilter', { selectedTypes })
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
    dispatch('chartData/refresh', {}, { root: true })
    dispatch('reportChartData/refresh', null, { root: true })
  },

  reset({ dispatch, commit }, { portfolioId }) {
    commit('reset')
    dispatch('portfolioBuildingTags/refresh', { portfolioId: portfolioId }, { root: true })
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
    dispatch('chartData/refresh', {}, { root: true })
    dispatch('reportChartData/load', portfolioId, { root: true })
  },
}

// Mutations
const mutations = {
  //
  setSearchText(state, search) {
    state.search = search
  },

  //
  setSortOrder(state, order) {
    state.sort_order = order
  },

  //
  setSortReverse(state, value) {
    state.sort_reverse = value
  },

  //
  setTagFilters(state, { tagGroupId, tagIdFilters }) {
    if (tagIdFilters.length > 0) {
      state.filters = {
        ...state.filters,
        [tagGroupId]: tagIdFilters,
      }
    } else {
      state.filters = _.omit(state.filters, tagGroupId)
    }
  },

  //
  setReidaYear(state, { year }) {
    state.reida = {
      ...state.reida,
      year,
    }
  },

  //
  toggleReidaType(state, { type }) {
    if (state.reida.types.includes(type)) {
      state.reida = {
        ...state.reida,
        types: state.reida.types.filter((t) => t !== type),
      }
    } else {
      state.reida = {
        ...state.reida,
        types: [...state.reida.types, type],
      }
    }
  },

  //
  setStatusFilter(state, { selectedTypes }) {
    state.status = selectedTypes
  },

  //
  reset(state) {
    state.search = ''
    state.sort_order = 'identifier'
    state.sort_reverse = false
    state.filters = {}
    state.reida = {
      year: null,
      types: ['EXISTING_AND_CONFORM'],
    }
    state.status = []
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
