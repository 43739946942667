// initial state
const state = {
  invalidFields: [],
  error: null,
  pending: false,
  editMode: false,
}

// getters
const getters = {
  getInvalidFields: (state) => state.invalidFields,

  getError: (state) => state.error,

  getPending: (state) => state.pending,

  getEditMode: (state) => state.editMode,
}

// actions
const actions = {
  setInvalidFields({ commit }, invalidFields) {
    commit('setInvalidFields', invalidFields)
  },

  setError({ commit }, errorMessage) {
    commit('setError', errorMessage)
  },

  setPending({ commit }, pending) {
    commit('setPending', pending)
  },

  setEditMode({ commit }, editMode) {
    commit('setEditMode', editMode)
  },

  resetFormState({ commit }) {
    commit('setInvalidFields', [])
    commit('setError', null)
    commit('setPending', false)
    commit('setEditMode', false)
  },
}

// mutations
const mutations = {
  setInvalidFields(state, invalidFields) {
    state.invalidFields = invalidFields
  },

  setError(state, errorMessage) {
    state.errorMessage = errorMessage || null
  },

  setPending(state, pending) {
    state.pending = pending
  },

  setEditMode(state, editMode) {
    state.editMode = editMode
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
