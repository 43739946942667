import compassApi from '@/services/compass-api'

// initial state
const state = {
  lastSeenEventId: null,
}

// getters
const getters = {}

// mutations
const mutations = {
  setLastSeenEventId(state, { date }) {
    state.lastSeenEventId = date
  },
}

// actions
const actions = {
  async initialize({ commit }) {
    const data = await compassApi.call(`/portfolios/last-event-id`)
    commit('setLastSeenEventId', { date: data })
  },

  async loadEvents({ dispatch, commit, state }) {
    if (state.lastSeenEventId === 0 || state.lastSeenEventId) {
      const data = await compassApi.call(`/portfolios/events?after=${state.lastSeenEventId}`)
      if (data && data.length > 0) {
        commit('setLastSeenEventId', { date: data[data.length - 1].id })
        data.forEach((e) => {
          if (e.type === 'SIMULATION_END') {
            dispatch('building/markDirty', e.building_id, { root: true })
          }
        })
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
