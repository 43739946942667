import Vue from 'vue'
import { genericLoadWithPortfolioAndQuery, genericRefreshWithPortfolioAndQuery } from '@/store/storeUtil'

const state = {
  loading: true,
  loaded: false,
  portfolioBuildingTags: [], // List of portfolio building tags
  // Structure of a portfolio building tag:
  // {
  //   id
  //   name
  //   auto
  //   tags: []
  //   total_available
  // }

  // Context
  latestRequestId: null,
  portfolioId: null,
  query: '{}',
}

// Getters
const getters = {
  getPortfolioBuildingTags(state) {
    return () => {
      return state.portfolioBuildingTags
    }
  },

  getPortfolioBuildingTagsLoading(state) {
    return () => {
      return state.loading
    }
  },

  getPortfolioBuildingTagsLoaded(state) {
    return () => {
      return state.loaded
    }
  },
}

// Actions
const actions = {
  async load({ commit, rootState, state }, { portfolioId }) {
    await genericLoadWithPortfolioAndQuery({
      commit,
      state,
      rootState,
      portfolioId,
      urlFct: (portfolioId, query) => `/portfolios/${portfolioId}/building-tags?query=${query}`,
    })
  },

  async refresh({ commit, rootState, state }) {
    await genericRefreshWithPortfolioAndQuery({
      commit,
      state,
      rootState,
      urlFct: (portfolioId, query) => `/portfolios/${portfolioId}/building-tags?query=${query}`,
    })
  },
}

// Mutations
const mutations = {
  setContext(state, { portfolioId, query, requestId }) {
    Vue.set(state, 'portfolioId', portfolioId)
    Vue.set(state, 'query', query)
    Vue.set(state, 'latestRequestId', requestId)
  },

  reset(state) {
    Vue.set(state, 'portfolioBuildingTags', [])
  },

  loading(state) {
    Vue.set(state, 'loaded', false)
    Vue.set(state, 'loading', true)
  },

  loaded(state, { data }) {
    Vue.set(state, 'portfolioBuildingTags', data)
    Vue.set(state, 'loaded', true)
    Vue.set(state, 'loading', false)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
