<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      loggedIn: 'account/loggedIn',
    }),
  },

  watch: {
    loggedIn(value) {
      if (value) {
        this.__createEventInterval()
      } else {
        this.__clearEventInterval()
      }
    },
  },

  created() {
    if (this.loggedIn) {
      this.__createEventInterval()
    }
  },

  destroyed() {
    if (this.intervalIdEvent) {
      this.__clearEventInterval()
    }
  },

  methods: {
    ...mapActions({
      __loadEvents: 'events/loadEvents',
      __initializeEvents: 'events/initialize',
    }),

    __createEventInterval() {
      this.__initializeEvents()
      this.intervalIdEvent = setInterval(() => this.__loadEvents(), 7000)
    },

    __clearEventInterval() {
      clearInterval(this.intervalIdEvent)
      this.intervalIdEvent = undefined
    },
  },
}
</script>
