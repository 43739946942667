import _ from 'lodash'
import { BUILDING_STATUS } from '@/models/Building'

// initial state
const state = {
  portfolio: {},
  building: {},
  canSwitchPermissions: false,
  switchedPermissionsFor: [], // contains building ids
}

// getters
const getters = {
  getPortfolioPermission: (state) => (key) => {
    return state.portfolio?.[key] || false
  },

  hasPortfolioPermissions(state) {
    return !_.isEmpty(state.portfolio)
  },

  getBuildingPermission: (state) => (key) => {
    return state.building?.[key] || false
  },

  canSwitchPermissions(state) {
    return state.canSwitchPermissions
  },

  isAdminEditOverrideActive: (state) => (buildingId) => {
    return state.switchedPermissionsFor.includes(buildingId)
  },
}

// actions
const actions = {
  // Called from portfolio store loadDetailsById and refreshDetailsById
  async setPortfolioPermissions({ commit }, permissions) {
    commit('portfolioPermissions', _.clone(permissions))
  },

  // Called from building store loadDetailsById and refreshDetailsById
  async setBuildingPermissions({ rootGetters, commit, getters }, buildingData) {
    const { permissions, uiPermissions } = buildingData || {}
    const isAdmin = rootGetters['account/admin']
    const buildingPermissions = getters.isAdminEditOverrideActive(buildingData.id)
      ? permissions
      : isAdmin
      ? uiPermissions
      : permissions
    const canSwitchPermissions = isAdmin && buildingData.status === BUILDING_STATUS.REPORTER

    if (buildingPermissions) {
      commit('buildingPermissions', buildingPermissions)
      commit('canSwitchPermissions', canSwitchPermissions)
    }
  },

  async switchBuildingPermissions({ rootGetters, commit, getters }, buildingData) {
    const { permissions, uiPermissions } = buildingData || {}
    const isAdmin = rootGetters['account/admin']
    commit('toggleSwitchedPermissionsFor', buildingData.id)
    commit(
      'buildingPermissions',
      getters.isAdminEditOverrideActive(buildingData.id) ? permissions : isAdmin ? uiPermissions : permissions
    )
  },
}

// mutations
const mutations = {
  portfolioPermissions(state, permissions) {
    state.portfolio = permissions || {}
  },

  buildingPermissions(state, permissions) {
    state.building = permissions || {}
  },

  toggleSwitchedPermissionsFor(state, buildingId) {
    state.switchedPermissionsFor = _.xor(state.switchedPermissionsFor, [buildingId])
  },

  canSwitchPermissions(state, value) {
    state.canSwitchPermissions = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
