<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('queue', ['buildingSimulationQueue']),

    ...mapGetters({
      loggedIn: 'account/loggedIn',
    }),
  },

  watch: {
    loggedIn(value) {
      if (value) {
        this.__createQueueInterval()
      } else {
        this.__clearQueueInterval()
      }
    },
  },

  created() {
    if (this.loggedIn) {
      this.__loadQueue()
      this.__createQueueInterval()
    }
  },

  destroyed() {
    if (this.intervalIdQueue) {
      this.__clearQueueInterval()
    }
    this.__reset()
  },

  methods: {
    ...mapActions({
      __loadQueue: 'queue/loadBuildingSimulationQueue',
      __reset: 'queue/resetBuildingSimulationQueue',
    }),

    __createQueueInterval() {
      this.intervalIdQueue = setInterval(() => this.__loadQueue(), 7000)
    },

    __clearQueueInterval() {
      clearInterval(this.intervalIdQueue)
      this.intervalIdQueue = undefined
    },
  },
}
</script>
