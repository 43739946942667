import Vue from 'vue'
import Vuex from 'vuex'

import { createStore } from 'vuex-extensions'

import account from './modules/account'
import building from './modules/building'
import locale from './modules/locale'
import portfolio from './modules/portfolio'
import portfolioBuildingTags from './modules/portfolioBuildingTags'
import users from './modules/users'
import query from './modules/query'
import queue from './modules/queue'
import events from './modules/events'
import sustainabilityYears from './modules/sustainabilityYears'
import sustainabilityIndicatorFactors from './modules/sustainabilityIndicatorFactors'
import chartData from './modules/chartData'
import reportChartData from './modules/reportChartData'
import portfolioBuildingsForList from './modules/portfolioBuildingsForList'
import ui from './modules/ui'
import reports from './modules/reporting'
import permissions from './modules/permissions'
import form from './modules/form'
import statusEvents from './modules/statusEvents'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default createStore(Vuex.Store, {
  modules: {
    account,
    building,
    locale,
    portfolio,
    portfolioBuildingTags,
    users,
    query,
    queue,
    events,
    sustainabilityYears,
    sustainabilityIndicatorFactors,
    chartData,
    reportChartData,
    portfolioBuildingsForList,
    ui,
    reports,
    permissions,
    form,
    statusEvents,
  },

  strict: debug,
})
