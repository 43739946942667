import Vue from 'vue'

import compassApi from '@/services/compass-api'
import de from '@/locales/de.json'

const state = {
  error: null,
  loadingStatusEvents: false,
  loadedStatusEvents: false,
  statusEvents: null,
  loadingStatusPendingChanges: false,
  loadedStatusPendingChanges: false,
  statusPendingChanges: [],
}

// Getters
const getters = {
  getStatusEvents(state) {
    return () => {
      return state.statusEvents
    }
  },

  getStatusPendingChanges(state) {
    return () => {
      return state.statusPendingChanges
    }
  },
}

// Actions
const actions = {
  async getStatusEventsByPortfolioId(
    { state, commit },
    { portfolioId, dates = [new Date(new Date().setFullYear(new Date().getFullYear() - 1)), new Date()] }
  ) {
    commit('statusEventsLoading')
    try {
      const start = dates[0].toISOString()
      const end = dates[1].toISOString()
      const statusEvents = await compassApi.call(`/portfolios/${portfolioId}/pricing?from=${start}&to=${end}`)
      commit('statusEventsLoaded', {
        portfolioId,
        statusEvents: statusEvents,
      })
    } catch (e) {
      commit('statusEventsLoaded', {
        portfolioId,
        statusEvents: null,
      })
    }
  },

  async getStatusPendingChangesByPortfolioId({ state, commit }, { portfolioId }) {
    commit('statusPendingChangesLoading')
    try {
      const statusPendingChanges = await compassApi.call(`/portfolios/${portfolioId}/buildings/pendingStatusChanges`)
      commit('statusPendingChangesLoaded', {
        portfolioId,
        statusPendingChanges: statusPendingChanges,
      })
    } catch (e) {
      commit('statusPendingChangesLoaded', {
        portfolioId,
        statusPendingChanges: [],
      })
    }
  },

  async applyStatusChange({ state, commit, dispatch }, { portfolioId, ids = [] }) {
    try {
      await compassApi.call(`/portfolios/${portfolioId}/apply-status-change-pending`, 'PUT', { ids })
    } catch (e) {
      commit('error', e)
    } finally {
      dispatch('getStatusPendingChangesByPortfolioId', { portfolioId })
      dispatch('getStatusEventsByPortfolioId', { portfolioId })
      dispatch('portfolio/refreshAll', null, { root: true })
    }
  },

  async reset({ state, commit }) {
    commit('reset')
  },

  async exportStatusEventsAsCsv({ state, commit }, { portfolioId, dates }) {
    try {
      const start = dates[0].toISOString()
      const end = dates[1].toISOString()
      const translations = de.pricing
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      await compassApi.fetchFile(`/portfolios/${portfolioId}/pricing/export-as-csv?from=${start}&to=${end}`, 'POST', {
        timezone,
        translations,
      })
    } catch (e) {
      commit('error', e)
    }
  },

  async createSnapshot({ state, commit, dispatch }, { portfolioId, dates = [] }) {
    try {
      await compassApi.call(`/portfolios/${portfolioId}/pricing/snapshots`, 'POST')
    } catch (e) {
      commit('error', e)
    } finally {
      dispatch('getStatusEventsByPortfolioId', { portfolioId, dates })
    }
  },
}

// Mutations
const mutations = {
  reset(state) {
    Vue.set(state, 'error', null)
    Vue.set(state, 'statusEvents', null)
    Vue.set(state, 'statusPendingChanges', [])
  },

  error(state, error) {
    Vue.set(state, 'error', error)
  },

  statusEventsLoading(state) {
    Vue.set(state, 'loadedStatusEvents', false)
    Vue.set(state, 'loadingStatusEvents', true)
  },

  statusEventsLoaded(state, { statusEvents }) {
    Vue.set(state, 'statusEvents', statusEvents)
    Vue.set(state, 'loadedStatusEvents', true)
    Vue.set(state, 'loadingStatusEvents', false)
  },

  statusPendingChangesLoading(state) {
    Vue.set(state, 'loadedStatusPendingChanges', false)
    Vue.set(state, 'loadingStatusPendingChanges', true)
  },

  statusPendingChangesLoaded(state, { statusPendingChanges }) {
    Vue.set(state, 'statusPendingChanges', statusPendingChanges)
    Vue.set(state, 'loadedStatusPendingChanges', true)
    Vue.set(state, 'loadingStatusPendingChanges', false)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
