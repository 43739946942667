import i18n from '@/plugins/i18n'

// initial state
const state = {
  available: {
    de: 'Deutsch',
    // fr: 'Français'
  },
  current: 'de',
}

// getters
const getters = {
  lang(state) {
    return state.current
  },
  keys(state) {
    return Object.keys(state.available)
  },
}

// actions
const actions = {
  setLocale({ commit }, locale) {
    commit('setLocale', locale)
  },
}

// mutations
const mutations = {
  setLocale(state, locale) {
    if (state.available[locale]) {
      state.current = locale
      i18n.locale = locale
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
