import Vue from 'vue'

import {
  genericLoadWithPortfolioAndQuery,
  genericRefreshWithPortfolioAndQuery,
  genericHardRefreshWithPortfolioAndQuery,
} from '@/store/storeUtil'

const state = {
  loading: true,
  loaded: false,
  chartData: null,
  // Context
  latestRequestId: null,
  portfolioId: null,
  query: '{}',
}

const getters = {
  getChartData(state) {
    return () => {
      if (!state.loaded) {
        return []
      }
      return [...state.chartData]
    }
  },

  getChartDataLoading(state) {
    return () => {
      return state.loading
    }
  },

  getChartDataLoaded(state) {
    return () => {
      return state.loaded
    }
  },
}

const actions = {
  async load({ commit, rootState, state }, { portfolioId }) {
    await genericLoadWithPortfolioAndQuery({
      commit,
      state,
      rootState,
      portfolioId,
      urlFct: (portfolioId, query) => `/portfolios/${portfolioId}/chart_data?query=${query}`,
    })
  },

  async refresh({ commit, rootState, state }) {
    await genericRefreshWithPortfolioAndQuery({
      commit,
      state,
      rootState,
      urlFct: (portfolioId, query) => `/portfolios/${portfolioId}/chart_data?query=${query}`,
    })
  },

  async hardRefresh({ commit, rootState, state }) {
    await genericHardRefreshWithPortfolioAndQuery({
      commit,
      state,
      rootState,
      urlFct: (portfolioId, query) => `/portfolios/${portfolioId}/chart_data?query=${query}`,
    })
  },
}

const mutations = {
  setContext(state, { portfolioId, query, requestId }) {
    Vue.set(state, 'portfolioId', portfolioId)
    Vue.set(state, 'query', query)
    Vue.set(state, 'latestRequestId', requestId)
  },

  reset(state) {
    Vue.set(state, 'chartData', [])
  },

  // When chartData are being loaded
  loading(state) {
    Vue.set(state, 'loaded', false)
    Vue.set(state, 'loading', true)
    Vue.set(state, 'chartData', [])
  },

  // When chartData have been loaded
  loaded(state, { data }) {
    Vue.set(state, 'chartData', data)
    Vue.set(state, 'loaded', true)
    Vue.set(state, 'loading', false)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
