import compassApi from '@/services/compass-api'

// initial state
const state = {
  users: null,
}

// getters
const getters = {
  getAll(state) {
    return state.users
  },

  getById(state) {
    return (id) => {
      if (state.users === null) {
        return null
      }
      return state.users.find((u) => u.id === id)
    }
  },
}

// actions
const actions = {
  async loadAll({ commit }) {
    const users = await compassApi.call('/users')
    commit('usersLoaded', users)
  },

  async updatePasswordById({ commit }, { id, password }) {
    await compassApi.call(`/users/${id}/password`, 'PUT', { password: password })
  },

  async addUser({ dispatch }, user) {
    await compassApi.call('/users', 'POST', user)
    dispatch('loadAll')
  },

  async updateUser({ dispatch }, user) {
    await compassApi.call(`/users/${user.id}`, 'PUT', user)
    dispatch('loadAll')
  },

  async deleteUser({ dispatch }, id) {
    await compassApi.call(`/users/${id}`, 'DELETE')
    dispatch('loadAll')
  },

  async addAdminRoleToUser({ dispatch }, id) {
    await compassApi.call(`/users/${id}/admin`, 'POST')
    dispatch('loadAll')
  },

  async removeAdminRoleFromUser({ dispatch }, id) {
    await compassApi.call(`/users/${id}/admin`, 'DELETE')
    dispatch('loadAll')
  },
}

// mutations
const mutations = {
  usersLoaded(state, users) {
    state.users = users.sort((a, b) => {
      const lastNameCompare = a.last_name.localeCompare(b.last_name)
      return lastNameCompare !== 0 ? lastNameCompare : a.first_name.localeCompare(b.first_name)
    })
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
