import Vue from 'vue'

import compassApi from '@/services/compass-api'

const state = {
  loading: true,
  loaded: false,
  portfolioId: null,
  yearId: null,
  sustainabilityIndicatorFactors: null, // Overview of sustainability indicator factors
}

// Getters
const getters = {
  // Warning - Currently fragile:
  // Always use this getter for retrieving the sustainability indicator factors
  // Using the load action directly will cause the inferred values to not be added
  getSustainabilityIndicatorFactors(state) {
    return () => {
      if (!state.loaded) {
        return undefined
      }
      // Infer missing sustainability indicator factors (s12e, s123e, per)
      return {
        ...state.sustainabilityIndicatorFactors,
        entries: state.sustainabilityIndicatorFactors.entries.map((sustainabilityIndicatorFactor) => {
          const { s1e, s2e, s3e, pe, penr } = sustainabilityIndicatorFactor.factors
          //
          const inferredS1e = s1e.manual !== null ? s1e.manual : s1e.inherited
          const inferredS2e = s2e.manual !== null ? s2e.manual : s2e.inherited
          const inferredS3e = s3e.manual !== null ? s3e.manual : s3e.inherited
          const inferredPe = pe.manual !== null ? pe.manual : pe.inherited
          const inferredPenr = penr.manual !== null ? penr.manual : penr.inherited
          //
          const s12e = {
            inherited: +((inferredS1e || 0) + (inferredS2e || 0)).toFixed(8),
            manual: null,
            source: 'INFERRED',
            source_year: Math.max(s1e.source_year, s2e.source_year),
          }
          //
          const s123e = {
            inherited: +((inferredS1e || 0) + (inferredS2e || 0) + (inferredS3e || 0)).toFixed(8),
            manual: null,
            source: 'INFERRED',
            source_year: Math.max(s1e.source_year, s2e.source_year, s3e.source_year),
          }
          //
          const per = {
            inherited: +((inferredPe || 0) - (inferredPenr || 0)).toFixed(8),
            manual: null,
            source: 'INFERRED',
            source_year: Math.max(pe.source_year, penr.source_year),
          }
          return {
            ...sustainabilityIndicatorFactor,
            factors: {
              ...sustainabilityIndicatorFactor.factors,
              s12e: s12e,
              s123e: s123e,
              per: per,
            },
          }
        }),
      }
    }
  },
}

// Actions
const actions = {
  // Load overview of sustainability indicator factors of one portfolio and one year
  async loadSustainabilityIndicatorFactorsByPortfolioIdAndYearId({ commit }, { portfolioId, yearId }) {
    commit('sustainabilityIndicatorFactorsLoading')
    if (portfolioId && yearId) {
      commit('setContext', { portfolioId, yearId })
      const sustainabilityIndicatorFactors = await compassApi.call(
        `/portfolios/${portfolioId}/sustainability/overview/${yearId}`
      )
      commit('sustainabilityIndicatorFactorsLoaded', { sustainabilityIndicatorFactors })
    }
  },

  async update({ dispatch }, { portfolioId, yearId, sustainabilityIndicatorFactors }) {
    await compassApi.call(
      `/portfolios/${portfolioId}/sustainability/overview/${yearId}`,
      'PUT',
      sustainabilityIndicatorFactors
    )
    dispatch('refresh')
  },

  async refresh({ state, commit }) {
    if (state.portfolioId && state.yearId) {
      commit('sustainabilityIndicatorFactorsLoading')
      const sustainabilityIndicatorFactors = await compassApi.call(
        `/portfolios/${state.portfolioId}/sustainability/overview/${state.yearId}`
      )
      commit('sustainabilityIndicatorFactorsLoaded', { sustainabilityIndicatorFactors })
    }
  },
}

// Mutations
const mutations = {
  setContext(state, { portfolioId, yearId }) {
    Vue.set(state, 'portfolioId', portfolioId)
    Vue.set(state, 'yearId', yearId)
  },

  // When sustainabilityIndicatorFactors are being loaded
  sustainabilityIndicatorFactorsLoading(state) {
    Vue.set(state, 'loaded', false)
    Vue.set(state, 'loading', true)
    Vue.set(state, 'sustainabilityIndicatorFactors', [])
  },

  // When sustainabilityIndicatorFactors have been loaded
  sustainabilityIndicatorFactorsLoaded(state, { sustainabilityIndicatorFactors }) {
    Vue.set(state, 'sustainabilityIndicatorFactors', sustainabilityIndicatorFactors)
    Vue.set(state, 'loaded', true)
    Vue.set(state, 'loading', false)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
