// https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link/19328891#19328891
// https://stackoverflow.com/questions/31959487/utf-8-encoidng-issue-when-exporting-csv-file-javascript
export const saveFile = (data, filename, mimetype) => {
  let a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  let blob = new Blob(['\ufeff' + data], { type: mimetype })
  let url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(url)
}

// Validate a string as a valid email address
export const isValidEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}
